import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

if (window.ENV !== "development") {
  Sentry.init({
    dsn: "https://5d4f598945f34e9fb35da80611bbbdbd@o562027.ingest.sentry.io/5795990",
    integrations: [new Integrations.BrowserTracing()],
    environment: window.ENV,
    tracesSampleRate: 0.2,
  });
}
